import { PiCardDef, memo, showPage, type PiRegister } from "@pihanga2/core"

import {
  AgentT,
  AppState,
  CrewTemplateT,
  DATE_FORMATTER,
  JobCatalog,
  JobCommonT,
  JobPendingT,
  JobSuccessT,
  JobT,
  PrepareOrderT,
  TaskT,
  TemplateCatalog,
} from "./app.types"
import {
  Accordion,
  Button,
  Card,
  DecoratorE,
  Form,
  FormInput,
  Link,
  type DecoratorT,
  MarkdownViewer,
  Stack,
  Typography,
} from "@pihanga2/cards"

import { AppCard } from "./app.types"
import { HeaderT } from "./cards/pageD3/pageD3.types"
import { createOrderCrewAction } from "./app.reducer"
import { Spinner } from "@pihanga2/cards/src/spinner"
import { Icon } from "./app.icons"

export function initJobPages(register: PiRegister): void {
  register.card(
    AppCard.JobList,
    Stack<AppState>({
      content: memo<[JobCatalog, TemplateCatalog], PiCardDef[], AppState, any>(
        (s) => [s.jobs, s.templates],
        ([jobs, templates]) => Object.values(jobs)
          .toSorted((a, b) => b.ordered_at - a.ordered_at)
          .map((j) =>
            j.status === "success"
              ? job_card(j as any)
              : job_pending_card(j, templates),
          ),
      ),
      spacing: 2,
      style: {
        joy: { overflow: "auto", px: { xs: 2, md: 4 }, py: 2, minHeight: 0 },
      },
    }),
  )

  function job_card(j: JobCommonT & JobSuccessT): PiCardDef {
    const args = Object.entries(j.inputs).map(([k, v]) => `${k}='${v}'`)
    const text = `${j.crew_name} with ${args.join(" and ")}.`
    const answer = Accordion({
      items: [
        {
          id: "jobDetail",
          title: text,
          content: MarkdownViewer({
            source: j.answer,
          }),
          defaultExpanded: false,
        },
      ],
      style: {
        joy: {
          items: {
            _default: {
              title: { paddingLeft: 0 },
              content: {
                backgroundColor: "#fff5cf",
                px: "1em",
              },
            },
          },
        },
      },
    })
    const sm = job_meta(j.order_id, j.ordered_at, j.process_time, j.run_time)
    return Card({
      content: [{ content: answer }, { content: sm }],
      variant: "soft",
      shadowSize: "lg",
      // style: {
      //   paddingBottom: 0,
      // }
    })
  }
}

function job_pending_card(
  j: JobCommonT & JobPendingT,
  templates: TemplateCatalog,
): PiCardDef {
  // const args = Object.entries(j.inputs).map(([k, v]) => `${k}='${v}'`)
  // const text = `${j.crew_name} with ${args.join(" and ")}.`
  let text = "???"
  if (j.crew_id) {
    const templ = templates[j.crew_id]
    if (templ) {
      text = jobTitle(templ.name, j.inputs)
    }
  }
  let startDecorator: DecoratorT
  if (j.status === "pending") {
    startDecorator = {
      type: DecoratorE.Card,
      cardName: Spinner({ height: "30px" }),
    }
  } else {
    startDecorator = {
      type: DecoratorE.Icon,
      icon: Icon.Crash,
      color: "danger",
      fontSize: "lg",
    }
  }
  const t = Typography({
    text,
    // startDecorator: {
    //   type: DecoratorE.Card,
    //   cardName: Spinner({ height: "30px" }),
    // },
    startDecorator,

  })
  const sm = job_meta(j.order_id, j.ordered_at)
  return Card({
    content: [{ content: t }, { content: sm }],
    variant: "soft",
    shadowSize: "lg",
  })
}

function job_meta(
  order_id: string,
  ordered_at: number | string,
  process_time?: number,
  run_time?: number,
): PiCardDef {
  let oat: number
  if (typeof ordered_at === 'string') {
    oat = Date.parse(ordered_at)
  } else {
    oat = ordered_at
  }
  const a = [`__Started:__ ${DATE_FORMATTER.format(oat)}`]
  if (process_time !== undefined) {
    a.push(`__Process time:__ ${fmt_time(process_time)}`)
  }
  if (run_time !== undefined) {
    a.push(`__Run time:__ ${fmt_time(run_time)}`)
  }
  const oid = order_id.split(":")[3].split("-")[0]
  a.push(`__Order:__ ${oid} `)
  return MarkdownViewer({
    source: a.join("&nbsp;&nbsp;"),
    style: {
      fontSize: "0.8em",
    },
  })
}

export function jobPageHeader(s: AppState): HeaderT | undefined {
  if (s.activePage === AppCard.JobList) {
    return {
      title: "Jobs",
      subTitle: "Jobs the different crews already performed",
    }
  }
  if (s.activePage === AppCard.TemplateDetail) {
    if (s.prepareOrder) {
      return { title: s.prepareOrder.template.name }
    } else {
      return { title: "Unknown" }
    }
  }
}

function jobTitle(crew_name: string, inputs: { [k: string]: string }): string {
  const args = Object.entries(inputs).map(([k, v]) => `${k}='${v}'`)
  const text = `${crew_name} with ${args.join(" and ")}.`
  return text
}

function fmt_time(t: number): string {
  return `${t.toPrecision(3)}s`
}
