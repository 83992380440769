import {
  DispatchF,
  PiRegister,
  ReduxAction,
  createOnAction,
  onShowPage,
  registerActions,
  showPage,
} from "@pihanga2/core"
import {
  CreateOrderEvent,
  UserInfoEvent,
  dispatchIvcapLogout,
  onIvcapUserInfo,
  onOAuthProvider,
  PropT,
  orders,
  Aspect,
  aspects,
} from "@pihanga2/ivcap"
import { onUserLogout } from "@pihanga2/joy-ui"
import {
  AppState,
  AppCard,
  OrderCrewEvent,
  JobPendingT,
  JobCommonT,
  JobSuccessT,
} from "./app.types"
import { tracker } from "."

export const ACTION = registerActions("app", ["order_crew", "refresh_job_listing"])

export const onOrderCrew = createOnAction<OrderCrewEvent>(ACTION.ORDER_CREW)

const SERVICE_ID = "urn:ivcap:service:250ba3ee-6773-5ff6-8aa2-6109452259aa"

export const createOrderCrewAction = (
  ev: OrderCrewEvent,
): ReduxAction & OrderCrewEvent => ({
  type: ACTION.ORDER_CREW,
  ...ev,
})

export const TEMPLATE_PATH = "templates"
export const JOB_PATH = "jobs"

export function reducerInit(register: PiRegister): void {
  const ivcapOrder = orders<AppState>(register)
  const ivcapAspect = aspects<AppState>(register)
  refreshTemplateList(ivcapAspect) // helps with pending orders

  onOrderCrew<AppState>(register, (s, a, d) => {
    if (!s.prepareOrder) return s

    const ctxt = s.prepareOrder
    if (s.prepareOrder?.id === a.crewID) {
      s.prepareOrder!.orderPending = true
    }
    const name = `${ctxt.template.name}`
    const parameters: any = ctxt.template.placeholders.map((p, i) => ({
      name: `p${i + 1}`,
      value: `${p}:${a.parameters[p]}`,
    }))
    // s.templates[a.crewID]
    parameters.push({
      name: "crew",
      value: ctxt.template.artifact,
    })

    const props: PropT<CreateOrderEvent> = {
      refID: name,
      name,
      serviceID: SERVICE_ID,
      parameters,
    }
    ivcapOrder.then((oi) => {
      oi.create(props).then((ev) => {
        console.log("order created", ev)
        showPage(d, [JOB_PATH])
      })
    })
    return s
  })

  onShowPage<AppState>(register, (state, a, d) => {
    const path = state.route.path
    if (path.length === 0) {
      showPage(d, [TEMPLATE_PATH])
      return state
    }
    const cat = path[0]
    const id = path.length === 2 ? path[1] : undefined
    switch (cat) {
      case TEMPLATE_PATH:
        return onShowTemplates(id, state, ivcapAspect, d)
      case JOB_PATH:
        return onShowJobs(id, state, ivcapAspect, d)
      default:
        showPage(d, [TEMPLATE_PATH])
    }
    return state
  })

  onOAuthProvider<AppState>(register, (state, a) => {
    state.oauthProviders[a.id] = a
    // if (state.activePage === AppCard.JobList) refreshJobList()
    return state
  })

  onIvcapUserInfo<AppState>(register.reducer, (state, a) => {
    const ev = { ...a } as UserInfoEvent
    delete (ev as any)["type"] // ts hack
    state.user = ev
    tracker.setUserID(ev.email ?? ev.name)
    return state
  })

  onUserLogout<AppState>(register, (state) => {
    dispatchIvcapLogout(register.reducer.dispatchFromReducer)
    return state
  })
}

function refreshJobList(ivcapAspect: Promise<Aspect<AppState>>) {
  ivcapAspect.then((ai) => {
    ai.list({
      schema: "urn:sd:crewai.result-meta.2",
      includeContent: true,
    }).reduce((s, { aspects, nextPage }) => {
      aspects.forEach(({ content }) => {
        const c = content as any
        const id = c.template
        const order_id = c["order-id"]
        const j = {
          ...s.jobs[id],
          id,
          status: "success",
          order_id,
          crew_name: c["template-name"],
          crew_id: "string",
          answer: c.answer,
          inputs: c.inputs,
          ordered_at: Date.parse(c["created-at"]),
          process_time: c["process-time"],
          run_time: c["run-time"],
        } as JobCommonT & JobSuccessT
        if (!j.ordered_at) j.ordered_at = Date.now()
        s.jobs[order_id] = j
      })
      return s
    })
  })
  ivcapAspect.then((ai) => {
    ai.list({
      schema: "urn:ivcap:schema:order-placed.1",
      filter: `service-id~=${SERVICE_ID}`,
      includeContent: true,
    }).reduce((s, { aspects, nextPage }) => {
      const pending = aspects.filter((a) => a.content.status !== "succeeded")
      if (pending.length > 0) {
        // schedule refresh
      }
      pending.forEach((a) => {
        const orderID = a.entity
        if (!s.jobs[orderID]) {
          const c = a.content
          const inputs: { [k: string]: string } = {}
          let crew_id: string | undefined
          const parameters = c.parameters as { name: string; value: string }[]
          parameters.forEach(({ name, value }) => {
            if (name === "crew") {
              const t = Object.values(s.templates).find(
                ({ artifact }) => artifact === value,
              )
              if (t) crew_id = t.id
            } else if (/^p[1-9]+/.test(name)) {
              const a = value.split(":")
              if (a.length >= 2) {
                const n = a[0]
                const v = a.slice(1).join(":")
                inputs[n] = v
              }
            }
          })
          if (Object.keys(inputs).length > 0 && crew_id) {
            s.jobs[orderID] = {
              status: c.status,
              order_id: orderID,
              ordered_at: Date.parse(c["ordered-at"]),
              inputs,
              crew_id,
            } as JobCommonT & JobPendingT
          }
        }
      })
      return s
      // {
      //   '0': {
      //     id: 'urn:ivcap:aspect:d7304ecb-7056-47e5-8f9c-69032a1b337f',
      //     entity: 'urn:ivcap:order:58011ea2-9a3b-4bcc-b9c7-2be80fe75eb1',
      //     schema: 'urn:ivcap:schema:order-placed.1',
      //     contentType: 'application/json',
      //     content: {
      //       'account-id': 'urn:ivcap:account:45a06508-5c3a-4678-8e6d-e6399bf27538',
      //       'duration-sec': 75,
      //       'finished-at': '2024-06-20T06:50:24Z',
      //       id: 'urn:ivcap:order:58011ea2-9a3b-4bcc-b9c7-2be80fe75eb1',
      //       name: 'Order 58011ea2-9a3b-4bcc-b9c7-2be80fe75eb1',
      //       'ordered-at': '2024-06-20T06:49:04.915849536Z',
      //       'ordered-by': 'urn:ivcap:principal:45a06508-5c3a-4678-8e6d-e6399bf27538:auth0%7C63eed6bb3b16f287edc3af41',
      //       parameters: [
      //         {
      //           name: 'cayp-order-id',
      //           value: 'urn:ivcap:order:58011ea2-9a3b-4bcc-b9c7-2be80fe75eb1'
      //         },
      //         {
      //           name: 'cayp-service-id',
      //           value: 'ivcap:service:250ba3ee-6773-5ff6-8aa2-6109452259aa'
      //         },
      //         {
      //           name: 'crew',
      //           value: 'urn:ivcap:artifact:6f939489-028e-45cb-b850-fb2143e36073'
      //         },
      //         {
      //           name: 'p1',
      //           value: 'recycling'
      //         },
      //         {
      //           name: 'p2',
      //           value: 'plastics in ocean'
      //         },
      //         {
      //           name: 'openai-model',
      //           value: 'gpt-3.5-turbo'
      //         }
      //       ],
      //       policy: 'urn:ivcap:policy:ivcap.base.order',
      //       'service-id': 'urn:ivcap:service:250ba3ee-6773-5ff6-8aa2-6109452259aa',
      //       'started-at': '2024-06-20T06:49:08Z',
      //       status: 'succeeded'
      //     }
      //   }
      // }
    })
  })
}

function refreshTemplateList(ivcapAspect: Promise<Aspect<AppState>>) {
  ivcapAspect.then((ai) => {
    ai.list({
      schema: "urn:sd:schema:icrew-crew.1",
      includeContent: true,
    }).reduce((s, { aspects }) => {
      const templates = { ...s.templates }
      aspects.forEach((el) => {
        const t = el.content as any
        t.id = t["$entity"]
        t.id = t.id.replaceAll(".", ":") // can be removed in production
        templates[t.id] = t
        // return t as CrewTemplateT
      })
      s.templates = templates

      // s.templates = aspects
      //   .map((el) => {
      //     const t = el.content as any
      //     t.id = t["$entity"]
      //     t.id = t.id.replaceAll(".", ":") // can be removed in production
      //     return t as CrewTemplateT
      //   })
      //   .sort((a, b) => a.name.localeCompare(b.name))
      return s
    })
  })
}

function onShowTemplates(
  id: string | undefined,
  state: AppState,
  ivcapAspect: Promise<Aspect<AppState>>,
  d: DispatchF,
): AppState {
  if (id) {
    state.activePage = AppCard.TemplateDetail
    if (state.prepareOrder?.id !== id) {
      const template = state.templates[id]
      if (!template) {
        showPage(d, [TEMPLATE_PATH])
        return state
      }
      const parameters = template.placeholders.reduce((p, k) => {
        p[k] = ""
        return p
      }, {} as { [k: string]: string })
      state.prepareOrder = {
        id,
        parameters,
        template,
        orderPending: false,
      }
    }
  } else {
    state.activePage = AppCard.TemplateList
    refreshTemplateList(ivcapAspect)
  }
  return state
}

function onShowJobs(
  id: string | undefined,
  state: AppState,
  ivcapAspect: Promise<Aspect<AppState>>,
  d: DispatchF,
): AppState {
  if (id) {
    state.activePage = AppCard.JobDetail
  } else {
    refreshJobList(ivcapAspect)
    state.activePage = AppCard.JobList
  }
  return state
}
