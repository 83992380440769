import { PiCardDef, memo, showPage, type PiRegister } from "@pihanga2/core"

import { Icon } from "./app.icons"
import { JyFramework } from "@pihanga2/joy-ui"
import { JyPage3 } from "./cards/pageD3"
import { AppState, CrewTemplateT } from "./app.types"
import { Router } from "@pihanga2/cards/src/router"
import { Card, Link, Stack } from "@pihanga2/cards"

import registerLoginPage from "./login.pihanga"
import { initTemplatePages, templatePageHeader } from "./template.pihanga"
import { AppCard } from "./app.types"
import { NavBarT } from "./cards/pageD3/pageD3.types"
import { UserInfoEvent, dispatchIvcapLogout } from "@pihanga2/ivcap"
import { JOB_PATH, TEMPLATE_PATH } from "./app.reducer"
import { initJobPages, jobPageHeader } from "./job.pihanga"
import { TITLE } from "./app.types"

export function init(register: PiRegister): void {
  registerLoginPage(register)
  initTemplatePages(register)
  initJobPages(register)

  register.card(
    AppCard.Framework,
    // JyFramework({ contentCard: AppCard.TopBox2 }),
    JyFramework({
      contentCard: Router<AppState>({
        contentCard: (s) => (s.user ? AppCard.Main : AppCard.Login),
      }),
    }),
  )

  register.card(
    AppCard.Main,
    JyPage3<AppState>({
      navBar: memo<UserInfoEvent | undefined, NavBarT, AppState, any>(
        (s) => s.user,
        (user) => ({
          title: TITLE,
          logoIcon: Icon.Logo,
          links: [
            { id: "templates", label: "Crews" },
            { id: "jobs", label: "Jobs" },
          ],
          user: user,
          showColorSchemeToggle: false,
        }),
      ),
      pageHeader: (s) =>
        templatePageHeader(s) ||
        jobPageHeader(s) || {
          title: "????",
        },
      search: {
        showing: false,
        // comment: "hfhffh",
      },
      content: (s) => s.activePage,
      onNavLink: (s, { id }, d) => {
        switch (id) {
          case "templates":
            showPage(d, [TEMPLATE_PATH])
            break
          case "jobs":
            showPage(d, [JOB_PATH])
            break
        }
        return s
      },
      onUserLogout: (s, _, d) => {
        dispatchIvcapLogout(d)
        return s
      },
    }),
  )
}
